<template>
  <div class="contract-add">

    <vs-row>
      <vs-col vs-type="flex" vs-align="center" vs-w="12">
        <vs-row>
          <vx-card title="Vertragspartner">
            <div class="vx-row">
              <div class="vx-col sm:w-1/2 w-full mb-1">
                <div class="vx-row mb-2">
                  <div class="vx-col w-full mb-0">

                    <div class="vx-row mb-2">
                      <div class="vx-col  w-full mb-0">
                        <label>Kunde auswählen:</label>
                        <mg-select v-model="client_id" :options="clients" name="client"
                                   placeholder="Kunde auswählen"
                                   track-by="id" label="company_name" data-vv-as="Kunde" v-validate="'required'">
                        </mg-select>
                        <span class="text-danger text-sm"
                              v-if="errors.has('clients')">Dieses Feld ist ein Pflichtfeld</span>
                      </div>
                    </div>

                    <ul class="centerx">
                      <li>
                        <vs-radio color="success" v-model="status" vs-value="1">Vertrag gültig</vs-radio>
                      </li>
                      <li>
                        <vs-radio color="warning" v-model="status" vs-value="2">Vertrag wurde gekündigt (noch aktiv)
                        </vs-radio>
                      </li>
                      <li>
                        <vs-radio color="danger" v-model="status" vs-value="3">Vertrag ist ausgelaufen (inaktiv)
                        </vs-radio>
                      </li>
                    </ul>

                  </div>
                </div>
              </div>
              <div class="vx-col sm:w-1/2 w-full mb-1">
                <label>Vertragstyp:</label>
                <mg-select v-model="type_id" :options="types" v-validate="'required'" name="types"
                           placeholder="Vertragstyp auswählen" track-by="id" label="name">
                </mg-select>


                <br><br>
                <vs-textarea label="Interner Kommentar" v-model="internal_comment"/>
              </div>
            </div>
          </vx-card>
          <transition name="fade">
            <vx-card title="Vertragsdetails" class="mt-base" v-if="client_id">
              <div class="vx-row">
                <div class="vx-col sm:w-1/2 w-full mb-1">


                  <div class="vx-row mb-2">
                    <div class="vx-col w-full mb-0">
                      <vs-alert color="#de5c00" :active="true" icon="info" class="mb-2 mt-5 alert-color vs-alert-dark" v-show="!checkContractStart">
                        Vertragsbeginn liegt in der Vergangenheit. Erste Abrechnung muss händisch erfolgen!
                      </vs-alert>
                      <label>Vertragsbeginn:</label><br>
                        <flat-pickr v-model="contract_start" :config="datePickerConfig" name="contract_start"
                                  v-validate="'required'" placeholder="Datum auswählen"></flat-pickr>
                      <br>
                      <small> <span class="link" @click="setContractStartYesterday">Gestern</span> |
                        <span class="link" @click.prevent="setContractStartToday">Heute</span> |
                        <span class="link" @click="setContractStartTomorrow">Morgen</span> </small>
                      <span class="text-danger text-sm" v-if="errors.has('contract_start')">Dieses Feld ist ein Pflichtfeld</span>
                    </div>
                  </div>


                  <div class="vx-row mb-2">
                    <div class="vx-col w-full mb-0">
                      <label>Vertragsende:</label><br>
                      <flat-pickr v-model="contract_end" :config="datePickerConfig" name="contract_end"
                                  v-validate="'required'" placeholder="Datum auswählen"></flat-pickr>
                      <br> <small> <span class="link" @click="setContractEnd">Vertragsende berechnen</span> </small>
                      <span class="text-danger text-sm" v-if="errors.has('contract_end')">Dieses Feld ist ein Pflichtfeld</span>
                    </div>
                  </div>


                </div>


                <div class="vx-col sm:w-1/2 w-full mb-1">

                  <div class="vx-row mb-2">
                    <div class="vx-col  w-full mb-0">
                      <label>Kündigungsfrist</label>
                      <mg-select v-model="period_of_notice" :options="contractOptions.period_of_notices"
                                 v-validate="'required'" name="period_of_notice" track-by="value"
                                 label="label"></mg-select>
                      <span class="text-danger text-sm" v-if="errors.has('period_of_notice')">Dieses Feld ist ein Pflichtfeld</span>
                    </div>
                  </div>


                  <div class="vx-row mb-2">
                    <div class="vx-col  w-full mb-0">
                      <label>Automatische Vertragsverlängerung bei keiner fristgerechten Kündigung</label>
                      <mg-select v-model="automatic_contract_extension"
                                 :options="contractOptions.automatic_contract_extensions" v-validate="'required'"
                                 name="automatic_contract_extension" track-by="value" label="label"></mg-select>
                      <span class="text-danger text-sm" v-if="errors.has('automatic_contract_extension')">Dieses Feld ist ein Pflichtfeld</span>
                    </div>
                  </div>


                  <div class="vx-row mb-2">
                    <div class="vx-col  w-full mb-0">
                      <label>Abrechnungsintervall</label>
                      <mg-select @select="checkChangesInBillingInterval"
                                 v-model="billing_interval"
                                 :options="contractOptions.billing_intervals"
                                 v-validate="'required'"
                                 name="billing_interval" track-by="value"
                                 label="label"></mg-select>
                      <span class="text-danger text-sm" v-if="errors.has('billing_interval')">Dieses Feld ist ein Pflichtfeld</span>
                    </div>
                  </div>


                  <div class="vx-row mb-2">
                    <div class="vx-col  w-full mb-0">
                      <label>Zeitpunkt der Abrechnung</label>
                      <mg-select v-model="billing_point_in_time" :options="contractOptions.billing_point_in_times"
                                 v-validate="'required'" name="billing_point_in_time" track-by="value"
                                 label="label"></mg-select>
                      <span class="text-danger text-sm" v-if="errors.has('billing_point_in_time')">Dieses Feld ist ein Pflichtfeld</span>
                    </div>
                  </div>

                </div>
              </div>


            </vx-card>
          </transition>
          <transition name="fade">
            <vx-card title="Vertragspositionen" class="mt-base" v-if="client_id">
              <div class="add-position">
                <vs-button @click="openArticleSearch(false)">Positionen hinzufügen</vs-button>
              </div>

              <!--
              <vs-row>

                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="9" class="table_head">
                  Artikelbezeichnung
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3" class="table_head">
                  Preis
                </vs-col>
              </vs-row>
              -->

              <draggable :list="contractItems" class="cursor-move">

                <vs-row class="table-content p-6 relative" v-for="(item, index) in contractItems">
                  <vs-col vs-w="9" class="pr-2">
                    <div class="vs-row">
                      <vs-col vs-w="6" class="pr-2 relative">

                        <div class="vs-row flex items-center mb-4">

                          <vs-col vs-w="6" class="pr-4">
                            <vs-input label="Produkt-Nr." v-model="item.ordernumber" readonly class="w-full"/>
                          </vs-col>

                          <vs-col vs-w="6" class="pt-4">
                            <span class="link" @click="openArticleSearch(index)" color="primary" type="filled">Anderes Produkt auswählen</span>
                          </vs-col>
                        </div>

                        <vs-input label="Produkt Bezeichnung" v-model="item.product_name" class="w-full"/>
                        <span class="text-danger text-sm"
                              v-if="errors.has('item.product_name')">Dieses Feld ist ein Pflichtfeld</span>


                        <div class="col-options">
                          <div class="col-options--button" @click="onClickProductDetails(index)">
                            <feather-icon icon="InfoIcon" class="w-5 h-5"/>
                          </div>
                        </div>

                      </vs-col>

                      <vs-col vs-w="6" class="pl-2">
                        <label class="vs-input--label">Beschreibung</label>
                        <vue-editor name="description" v-model="item.short_description" :editorToolbar="toolbar"
                                    data-vv-as="Beschreibung"/>
                      </vs-col>
                    </div>
                  </vs-col>

                  <vs-col vs-w="3" class="pl-2">

                    <label class="vs-input--label">Einzelpreis</label>
                    <vs-input v-model="item.unit_price" placeholder="Einzelpreis"
                              class="mb-2 w-full"
                              v-currency="{
                              currency: 'EUR',
                              locale: 'de',
                              autoDecimalMode: false,
                              decimalLength: 2,
                              min: 0,
                            }"

                    />

                    <div class="vs-row flex">
                      <vs-col vs-w="6" class="pr-4">
                        <label class="vs-input--label">Menge</label>

                        <vs-input-number color="success" @change="updateBillingInterval" v-model="item.amount"
                                         :step="0.5" class="mb-2"/>
                      </vs-col>
                      <vs-col vs-w="6">
                        <vs-select label="Einheit" @change="updateBillingInterval" v-model="item.volume_unit"
                                   class="w-full">
                          <vs-select-item :key="item.value" :value="item.value" :text="item.label"
                                          v-for="(item, index) in unitOptions(item)"/>
                        </vs-select>
                          <small><i v-if="item.article.is_hour_unit">Stundensatz-Artikel</i></small>
                      </vs-col>
                      <div v-show="item.billingIntervalErrorProp" class="vs-alert con-icon"><i
                          class="vs-icon notranslate icon-scale icon-alert material-icons null">error</i>Stimmt nicht
                        mit dem Abrechnungsintervall überein!
                      </div>
                    </div>

                    <p class="sum_total mt-4 block text-right text-lg font-bold"> Gesamtpreis: {{ itemSum(item) }} €</p>


                  </vs-col>

                  <div class="absolute -top-3 -right-3 bg-white shadow p-1 pb-0 border-r-2">
                    <i class="material-icons cursor-pointer text-danger hover:text-black"
                       v-on:click="removeItemRow(index)">
                      close
                    </i>
                  </div>

                </vs-row>
              </draggable>

              <vs-row class="text-right">
                <vs-col vs-type="flex" vs-w="12" class="total-container text-right">

                  <div class="total">
                    <div class="period_total mt-4">
                      <div class="label">Nettobetrag je Abrechnungsperiode:</div>
                      <div class="sum"><span id="subtotal">{{ total }}</span> <span class="currencyShort"><span
                          class="currency_sign ">€</span></span></div>
                      <div class="clear"></div>
                    </div>
                    <div class="contract_total">
                      <div class="label">Nettobetrag je Vertragslaufzeit:</div>
                      <div class="sum"><span id="total">{{ totalPeriodOfNotice }}</span> <span
                          class="currencyShort"><span
                          class="currency_sign ">€</span></span></div>
                      <div class="clear"></div>
                    </div>
                  </div>


                </vs-col>
              </vs-row>


            </vx-card>
          </transition>

          <vs-row class="mt-base">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="left" vs-w="6">
              <vs-button color="warning" type="border" class="mb-2" to="/contracts">Abbrechen</vs-button>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="right" vs-w="6">
              <vs-button class="mr-3 mb-2" :disabled="!validateForm" @click="createUser">Hinzufügen</vs-button>
            </vs-col>
          </vs-row>

        </vs-row>
      </vs-col>

    </vs-row>


    <vs-popup title="Produkt auswählen" :active.sync="popupArticleSearch">
      <p>
        <mg-select :options="articles"
                   track-by="id"
                   label="name"
                   @select="onSelectArticle">

        </mg-select>
      </p>
    </vs-popup>

    <product-detail-modal :product-id="selectedProductId" :active.sync="productDetailModalOpen"
                          @close="productDetailModalOpen=false"></product-detail-modal>


  </div>
</template>

<script>
import ApiService from "../../../api/index";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment';
import draggable from 'vuedraggable'
import PriceHelper from '@/mixins/helper/price.helper';
import MgSelect from '../../../components/mg-select/MgSelect'
import ContractOptions from "./contract-options";
import ProductDetailModal from "../../../components/product/ProductDetailModal";
import staticOptions from "@/mixins/static/options";

export default {
  components: {
    ProductDetailModal,
    flatPickr,
    draggable,
    MgSelect,
  },
  name: "AddContracts",
  data() {
    return {
      selected: [],
      log: [],
      client_id: 0,
      clients: [],
      contract_end: "",
      contract_start: "",
      datePickerConfig: {},
      internal_comment: "",
      status: 1,
      popupArticleSearch: false,
      contractItems: [],
      number2: 0,
      value1: "",
      short_description: null,
      amount: 0,
      itemVolumeUnit: "1",
      type_id: 1,
      types: [],
      period_of_notice: "1j",
      billing_interval: "1m",
      billing_point_in_time: "before",
      automatic_contract_extension: 12,
      contractOptions: {},
      articles: [],
      articleSearchItemIndex: null,
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        [{'color': []}],
      ],
      productDetailModalOpen: false,
      selectedProductId: null,
      billingIntervalErrorProp: false,
    }
  },
  created() {
    this.contractOptions = ContractOptions;
    ApiService.get('clients').then(response => {
      if (response.status !== 200) {
        return;
      }
      this.clients = response.data.result;

    });

    ApiService.get('articles').then(response => {
      if (response.status !== 200) {
        return;
      }
      this.articles = response.data.result;

    })


    ApiService.get('contract_types').then(response => {
      if (response.status !== 200) {
        return;
      }
      this.types = response.data.result;
    })
    this.checkBillingInterval()
  },
  computed: {
    checkContractStart() {
      var today = moment().format('YYYY-MM-DD');
      return today <= this.contract_start
    },
    validateForm() {
      return !this.errors.any() && this.automatic_contract_extension !== '' && this.billing_point_in_time !== '' && this.firstname !== '' && this.lastname !== '';
    },

    total() {
      const total = this.contractItems.reduce(
          (acc, item) => acc + PriceHelper.parseCommaFloat(item.unit_price) * PriceHelper.parseCommaFloat(item.amount),
          0
      );

      return PriceHelper.floatPointToComma(total);
    },

    totalPeriodOfNotice() {
      const total = this.contractItems.reduce(
          (acc, item) => acc + PriceHelper.parseCommaFloat(item.unit_price) * PriceHelper.parseCommaFloat(item.amount),
          0
      );
      return PriceHelper.floatPointToComma(total * this.automatic_contract_extension / 12 * this.billingIntervalToMultiplier);
    },
    parsedContractItems() {
      let newItems = [], clonedItems = JSON.parse(JSON.stringify(this.contractItems))

      clonedItems.forEach(function (item) {
        item.unit_price = PriceHelper.parseCommaFloat(item.unit_price)
        newItems.push(item)
      })
      return newItems
    },
    billingIntervalToMultiplier() {
      if (this.billing_interval === "2j") {
        return 0.5;
      } else if (this.billing_interval === "1j") {
        return 1;
      } else if (this.billing_interval === "6m") {
        return 2;
      } else if (this.billing_interval === "3m") {
        return 4;
      } else if (this.billing_interval === "1m") {
        return 12;
      } else if (this.billing_interval === "1me") {
        return 12;
      } else {
        return 12;
      }
    },
  },
  methods: {
    onClickProductDetails(i) {
      this.selectedProductId = this.contractItems[i].article_assignment_id;
      this.productDetailModalOpen = true;
    },
    itemSum(item) {
      return PriceHelper.floatPointToComma(PriceHelper.parseCommaFloat(item.unit_price) * PriceHelper.parseCommaFloat(item.amount));
    },
    setContractStartYesterday() {
      this.contract_start = moment().subtract(1, 'day').format('YYYY-MM-DD');
    },
    setContractStartTomorrow() {
      this.contract_start = moment().add(1, 'day').format('YYYY-MM-DD');
    },
    setContractStartToday() {
      this.contract_start = moment().format('YYYY-MM-DD');
    },
    setContractEnd() {
      this.contract_end = moment(new Date(this.contract_start)).add(this.automatic_contract_extension, 'month').format('YYYY-MM-DD');
    },
    createUser() {
      let me = this;

      if (!this.validateForm) return

      me.$vs.loading()

      const payload = {
        client_id: this.client_id,
        contract_start: this.contract_start,
        contract_end: this.contract_end,
        automatic_contract_extension: this.automatic_contract_extension,
        status: this.status,
        billing_interval: this.billing_interval,
        billing_point_in_time: this.billing_point_in_time,
        period_of_notice: this.period_of_notice,
        internal_comment: this.internal_comment,
        type_id: this.type_id,
        items: this.parsedContractItems

      }

      ApiService.post('contracts', payload).then((response) => {

        me.$vs.loading.close()

        me.$vs.notify({
          title: 'Erfolgreich',
          text: 'Ihr Account wurde erfolgreich angelegt',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success'
        })

        me.$router.push('/contracts');

      }).catch((response) => {

        me.$vs.loading.close()

        me.$vs.notify({
          title: 'Error',
          text: response.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })

      });

    },
    addItemRow() {
      this.contractItems.push({
        "id": null,
        "contract_id": 1,
        "amount": 1,
        "volume_unit": 1,
        "product_name": "",
        "short_description": "",
        "unit_price": 1,
        "article_assignment_id": null
      });
    },
    removeItemRow: function (index) {
      this.contractItems.splice(index, 1);
    },

    openArticleSearch(index) {
      if (index === false) {
        this.articleSearchItemIndex = null;
        return this.popupArticleSearch = true;
      }

      this.articleSearchItemIndex = index;
      this.popupArticleSearch = true;
    },
    onSelectArticle(article) {
      if (this.articleSearchItemIndex == null) {
        this.contractItems.push({
          article_assignment_id: article.id,
          article: article,
          product_name: article.name,
          unit_price: PriceHelper.floatPointToComma(article.price),
          volume_unit: parseInt(article.unit),
          short_description: article.description,
          ordernumber: article.ordernumber,
          amount: 1
        });
        this.checkBillingInterval()
        return this.popupArticleSearch = false;
      }

      if (this.contractItems[this.articleSearchItemIndex]) {
        this.contractItems[this.articleSearchItemIndex].article_assignment_id = article.id;
        this.contractItems[this.articleSearchItemIndex].product_name = article.name;
        this.contractItems[this.articleSearchItemIndex].unit_price = PriceHelper.floatPointToComma(article.price);
        this.contractItems[this.articleSearchItemIndex].volume_unit = parseInt(article.unit);
        this.contractItems[this.articleSearchItemIndex].short_description = article.description;
        this.contractItems[this.articleSearchItemIndex].ordernumber = article.ordernumber;
        this.contractItems[this.articleSearchItemIndex].amount = 1;
        this.contractItems.push();
      }

      this.popupArticleSearch = false;

    },
    checkBillingInterval() {
      for (let i = 0; i < this.contractItems.length; i++) {

        this.$set(this.contractItems[i], 'billingIntervalErrorProp', false)

        if (this.billing_interval === "1j" && (this.contractItems[i].amount != 1 || this.contractItems[i].volume_unit !== 3)) {
          this.contractItems[i].billingIntervalErrorProp = true;
        } else if (this.billing_interval === "2j" && (this.contractItems[i].amount != 2 || this.contractItems[i].volume_unit !== 3)) {
          this.contractItems[i].billingIntervalErrorProp = true;
        } else if (this.billing_interval === "3j" && (this.contractItems[i].amount != 3 || this.contractItems[i].volume_unit !== 3)) {
          this.contractItems[i].billingIntervalErrorProp = true;
        } else if (this.billing_interval === "4j" && (this.contractItems[i].amount != 4 || this.contractItems[i].volume_unit !== 3)) {
          this.contractItems[i].billingIntervalErrorProp = true;
        } else if (this.billing_interval === "6m" && (this.contractItems[i].amount != 6 || this.contractItems[i].volume_unit !== 2)) {
          this.contractItems[i].billingIntervalErrorProp = true;
        } else if (this.billing_interval === "3m" && (this.contractItems[i].amount != 3 || this.contractItems[i].volume_unit !== 2)) {
          this.contractItems[i].billingIntervalErrorProp = true;
        } else if (this.billing_interval === "1m" && (this.contractItems[i].amount != 1 || this.contractItems[i].volume_unit !== 2)) {
          this.contractItems[i].billingIntervalErrorProp = true;
        } else if (this.billing_interval === "1me" && (this.contractItems[i].amount != 1 || this.contractItems[i].volume_unit !== 2)) {
          this.contractItems[i].billingIntervalErrorProp = true;
        }
      }
    },
    updateBillingInterval() {
      this.checkBillingInterval();
    },
    checkChangesInBillingInterval(item) {
      this.billing_interval = item.value
      this.checkBillingInterval();
    },
      unitOptions(item) {
          if(item.article.is_hour_unit != undefined && item.article.is_hour_unit) {
              return staticOptions.units.filter(unit => unit.hour_unit);
          }

          return staticOptions.units;
      },

  },
  filters: {
    currency(value) {
      return value.toFixed(2);
    }
  }
}
</script>

<style lang="scss">
.contract-add {
  .quillWrapper {
    .ql-editor {
      height: 5rem;
      min-height: 5rem;
    }
  }
}

.vs-alert {
  background: #fbe4e1;
  width: 100%;
  color: #e24733;
  border-radius: 4px;
  border-width: 1px;
  margin-top: 10px;
}
</style>
